import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from '../icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {FieldResponse} from './field-response'
import PropTypes from 'prop-types'
import {getFieldNormalizer} from './get-field-normalizer'
import {getFieldParser} from './get-field-parser'

function Input(props) {
  const [type, setType] = React.useState('password')
  const inputRef = React.useRef(null)

  const onToggle = React.useCallback(() => {
    setType(() => (type === 'text' ? 'password' : 'text'))
    inputRef.current.focus()
  }, [type])

  React.useEffect(() => {
    // Moves the cursor to the end
    inputRef.current.selectionStart = inputRef.current.value.length
    inputRef.current.selectionEnd = inputRef.current.value.length
  }, [type])

  return (
    <ThemeUi.Flex>
      <ThemeUi.Input
        {...props}
        ref={inputRef}
        sx={{
          pr: '40px',
          bg: props.invalid && 'input-error-bg',
          borderColor: props.invalid && 'input-error-bg',
        }}
        type={type}
      />
      <ThemeUi.Grid
        sx={{
          'width': '40px',
          'ml': '-40px',
          'color': 'input-placeholder',
          'placeItems': 'center',
          'opacity': props.value ? 1 : 0,
          'transition': 'opacity 100ms ease-in-out, color 100ms ease-in-out',
          'cursor': props.value && 'pointer',
          '&:hover': {
            color: 'text',
          },
        }}
        onClick={() => props.value && onToggle()}
      >
        {type === 'text' ? <Icons.Visibility /> : <Icons.VisibilityOff />}
      </ThemeUi.Grid>
    </ThemeUi.Flex>
  )
}

Input.propTypes = {
  invalid: PropTypes.bool,
  value: PropTypes.string,
}

export function PasswordInputField(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })
  const translate = Herz.I18n.useTranslate(() => [
    field.label,
    field.placeholder,
  ])

  const visited = !R.includes(field.status, [
    Forms.FieldStatus.PRISTINE,
    Forms.FieldStatus.ABSENT,
  ])
  const value = field.value && field.value.toString()
  const invalid = !R.isEmpty(field.errors) && visited

  return (
    <ThemeUi.Grid sx={{gridGap: 0, position: 'relative'}}>
      <ThemeUi.Field
        as={Input}
        label={translate(field.label)}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        readOnly={props.readOnly}
        inputMode={props.inputMode}
        invalid={invalid}
        maxLength={props.maxLength}
        minLength={props.minLength}
        name={field.name}
        placeholder={translate(field.placeholder)}
        value={value}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
      <FieldResponse scope={props.scope} />
    </ThemeUi.Grid>
  )
}

PasswordInputField.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputMode: PropTypes.string,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  readOnly: PropTypes.bool,
  scope: PropTypes.string.isRequired,
}
