import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Component submits parent Form
 * @component SubmitButton
 * @param {Object} props Components props
 * @param {ReactNode|string=} props.children used for custom content
 * @param {boolean=} props.disabled if control is enabled or not
 * @param {string[]=} props.onlyScopes array of scopes to validate against
 * @returns {ReactNode} form submit-button
 */
export function SubmitButton(props) {
  const form = Forms.useFormContext()
  const errors =
    props.onlyScopes == null
      ? Forms.getFormErrors(form.state)
      : R.pick(props.onlyScopes, Forms.getFormErrors(form.state))

  const translate = Herz.I18n.useTranslate(
    () => [`${form.name}.submit.label`],
    [form.name]
  )

  return (
    <ThemeUi.Button
      disabled={
        !R.isNil(props.disabled)
          ? props.disabled
          : R.not(R.isEmpty(errors)) || props.loading
      }
      type="submit"
      sx={props.sx}
    >
      {props.children ? props.children : translate(`${form.name}.submit.label`)}
    </ThemeUi.Button>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onlyScopes: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
}
