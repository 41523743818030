import * as Api from '@rushplay/api-client'
import * as Constants from './constants'
import * as FormComponents from './form-components'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Notifications from '@rushplay/notifications'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {QueryPopup} from './query-popup'
import querystring from 'querystring'

const dataSchema = {
  type: 'object',
  properties: {
    password: {
      type: 'string',
      pattern: Constants.PasswordPattern,
    },
  },
  required: ['password'],
}

export function PasswordReset() {
  const translate = Herz.I18n.useTranslate()
  const history = ReactRouterDom.useHistory()
  const location = ReactRouterDom.useLocation()
  const {'password-reset': token} = querystring.parse(
    R.drop(1, location.search)
  )
  const dispatch = ReactRedux.useDispatch()

  const loading = ReactRedux.useSelector((state) =>
    Processes.isRunning(state.processes, {
      ids: ['PASSWORD_RESET_LOADING'],
    })
  )

  return (
    <Forms.Provider
      name="passwordReset"
      schema={dataSchema}
      onSubmit={(errors, data) => {
        if (R.isEmpty(errors)) {
          dispatch([
            Processes.start('PASSWORD_RESET_LOADING'),
            Api.updatePassword(token, data.password, data.password, {
              success: () => {
                history.push('?login=me')

                return [
                  Processes.stop('PASSWORD_RESET_LOADING'),
                  Notifications.add({
                    message: 'password-reset.success',
                    level: 'success',
                  }),
                ]
              },
              failure: () => [
                Processes.stop('PASSWORD_RESET_LOADING'),
                Notifications.add({
                  message: 'errors.general.unknown',
                  level: 'error',
                }),
              ],
              version: 2,
            }),
          ])
        }
      }}
    >
      <QueryPopup activeQueryName="password-reset">
        <Suspense.Boundary>
          <ThemeUi.Grid
            sx={{
              color: 'static-white',
              gridGap: 2,
            }}
          >
            <ThemeUi.Heading
              as="h1"
              sx={{
                fontSize: 4,
                textAlign: 'center',
              }}
            >
              {translate('password-reset.title')}
            </ThemeUi.Heading>
            <ThemeUi.Box
              sx={{
                pb: 2,
                fontSize: [1, 2],
              }}
            >
              {translate('password-reset.description')}
            </ThemeUi.Box>
            <FormComponents.PasswordInputField
              autoComplete="new-password"
              scope="#/properties/password"
            />
            <FormComponents.SubmitButton
              loading={loading}
              sx={{
                my: 2,
              }}
            >
              {translate('password-reset.submit')}
            </FormComponents.SubmitButton>
          </ThemeUi.Grid>
        </Suspense.Boundary>
      </QueryPopup>
    </Forms.Provider>
  )
}

Herz.I18n.Loader.preload(
  [
    'password-reset.submit',
    'password-reset.title',
    'password-reset.description',
  ],
  PasswordReset
)

// for @loadable/component
export default PasswordReset
