import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {FieldResponse} from './field-response'
import PropTypes from 'prop-types'
import {getFieldNormalizer} from './get-field-normalizer'
import {getFieldParser} from './get-field-parser'

/**
 * Component that extends native Input with field-updating capabilities
 * @component InputField
 * @param {Object} props Components props
 * @param {string=} props.autoComplete @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete}
 * @param {boolean=} props.autoFocus if form-control should be focused on render
 * @param {boolean=} props.disabled if control is enabled or not
 * @param {string=} props.format format to use for normalizer/parser
 * @param {string|number=} props.initialValue intial value of control
 * @param {string=} props.inputMode what keyboard to show on a mobile device @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode}
 * @param {string=} props.maxLength max amount of characters allowed
 * @param {string=} props.minLength min amount of characters allowed
 * @param {func=} props.normalize function to use for normalisation of field value
 * @param {func=} props.parse function to use for parsing of field value
 * @param {boolean=} props.readOnly if control is editable or not
 * @param {string} props.scope scope inside dataschema of parent Form
 * @param {string} [props.type=text] type of the input
 * @returns {ReactNode} Input that updates Form state via Field
 */
export function InputField(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })
  const translate = Herz.I18n.useTranslate(() => [
    field.label,
    field.placeholder,
  ])

  const visited = !R.includes(field.status, [
    Forms.FieldStatus.PRISTINE,
    Forms.FieldStatus.ABSENT,
  ])
  const value = field.value && field.value.toString()
  const invalid = !R.isEmpty(field.errors) && visited

  return (
    <ThemeUi.Grid sx={{gridGap: 0, position: 'relative'}}>
      <ThemeUi.Field
        as={ThemeUi.Input}
        label={translate(field.label)}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        readOnly={props.readOnly}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        minLength={props.minLength}
        name={field.name}
        placeholder={translate(field.placeholder)}
        type={props.type}
        value={value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        sx={{
          bg: invalid && 'input-error-bg',
          borderColor: invalid && 'input-error-bg',
        }}
      />
      <FieldResponse scope={props.scope} />
    </ThemeUi.Grid>
  )
}

InputField.defaultProps = {
  type: 'text',
}

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputMode: PropTypes.string,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  readOnly: PropTypes.bool,
  scope: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password']),
}
