import * as Hooks from './hooks'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ReactSpring from 'react-spring'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'
import querystring from 'querystring'

export function QueryPopup(props) {
  const location = ReactRouterDom.useLocation()
  const closeQuery = Hooks.useSafeUpdateQuery(
    R.mergeDeepRight({[props.activeQueryName]: null}, props.additionalQueries)
  )

  const active = R.path(
    [props.activeQueryName],
    querystring.parse(R.drop(1, location.search))
  )

  const bgTransitions = ReactSpring.useTransition(active, null, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  })

  const childTransitions = ReactSpring.useTransition(active, null, {
    from: {
      transform: 'scale(0.95)',
    },
    enter: {
      transform: 'scale(1)',
      trail: 500,
    },
    leave: {
      transform: 'scale(0.95)',
    },
  })

  return bgTransitions.map(
    ({item, key, props: styleProps}) =>
      item && (
        <ThemeUi.Box
          as={ReactSpring.animated.div}
          key={key}
          style={styleProps}
          sx={{
            zIndex: 6,
            position: 'fixed',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            display: 'grid',
            placeItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            p: 2,
          }}
        >
          {childTransitions.map(
            ({item, key, props: styleProps}) =>
              item && (
                <ThemeUi.Box
                  as={ReactSpring.animated.div}
                  key={key}
                  style={styleProps}
                  sx={{
                    width: '100%',
                    maxWidth: '475px',
                    backgroundColor: 'nav-background',
                    position: 'relative',
                    px: [2, 6],
                    py: [4, 6],
                    borderRadius: 1,
                  }}
                >
                  <ReactRouterDom.Link to={`?${closeQuery}`}>
                    <ThemeUi.Box
                      sx={{
                        'position': 'absolute',
                        'right': '-10px',
                        'top': '-10px',
                        'fontSize': 5,
                        'color': 'static-white',
                        'display': 'grid',
                        'placeItems': 'center',
                        'transition': 'transform 150ms ease-in-out',
                        '&:hover': {
                          opacity: 0.9,
                        },
                        '&:active': {
                          transform: 'scale(0.9)',
                        },
                      }}
                    >
                      <Icons.Cancel />
                    </ThemeUi.Box>
                  </ReactRouterDom.Link>
                  <ThemeUi.Box
                    sx={{
                      'overflowX': 'hidden',
                      'overflowY': 'auto',
                      'WebkitOverflowScrolling': 'touch',
                      '@media (hover: hover)': {
                        '::-webkit-scrollbar': {
                          width: '3px',
                          bg: 'transparent',
                        },
                        '::-webkit-scrollbar-thumb': {
                          bg: 'transparent',
                          borderRadius: '10px',
                        },
                        '::-webkit-scrollbar-track': {
                          bg: 'transparent',
                        },
                        '&:hover': {
                          '::-webkit-scrollbar-thumb': {
                            bg: 'rgba(255, 255, 255, 0.5)',
                          },
                        },
                      },
                    }}
                  >
                    {props.children}
                  </ThemeUi.Box>
                </ThemeUi.Box>
              )
          )}
        </ThemeUi.Box>
      )
  )
}

QueryPopup.defaultProps = {
  additionalQueries: {},
}

QueryPopup.propTypes = {
  activeQueryName: PropTypes.string.isRequired,
  additionalQueries: PropTypes.object,
  children: PropTypes.node,
}
