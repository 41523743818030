import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Extracted logic for basic SVG-component
 * @component SvgIcon
 * @param {Object} props Components props
 * @param {ReactNode} props.children Svg paths
 * @returns {ReactNode} SVG icon
 */
function SvgIcon(props) {
  return (
    <ThemeUi.Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{
        mt: '-0.105em',
        mb: '-0.145em',
        height: '1.25em',
        width: '1.25em',
        verticalAlign: '-0.185em',
      }}
    >
      {props.children}
    </ThemeUi.Box>
  )
}

SvgIcon.propTypes = {
  children: PropTypes.node,
}

export function ArrowDown() {
  return (
    <SvgIcon>
      <path fill="none" d="M0 .5h24v24H0V.5z" />
      <path
        fill="currentColor"
        d="M6 8a1 1 0 00-.76 1.65l6 7a1 1 0 001.52 0l6-7A1 1 0 0018 8H6z"
      />
    </SvgIcon>
  )
}

/**
 * Cancel icon
 * @see {@link https://material.io/resources/icons/?icon=cancel&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Cancel() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
      />
    </SvgIcon>
  )
}

/**
 * Close icon
 * @see {@link https://material.io/resources/icons/?icon=close&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Close() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
    </SvgIcon>
  )
}

/**
 * Error icon
 * @see {@link https://material.io/resources/icons/?icon=error&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Error() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
      />
    </SvgIcon>
  )
}

/**
 * Info icon
 * @see {@link https://material.io/resources/icons/?icon=info&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Info() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
      />
    </SvgIcon>
  )
}

/**
 * Navigate Back icon
 * @see {@link https://material.io/resources/icons/?icon=navigate_before&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function NavigateBack() {
  return (
    <SvgIcon>
      <path
        fill="currentColor"
        d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
      />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </SvgIcon>
  )
}

/**
 * Success icon
 * @see {@link https://material.io/resources/icons/?icon=check_circle&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Success() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
      />
    </SvgIcon>
  )
}

/**
 * Warning icon
 * @see {@link https://material.io/resources/icons/?icon=warning&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Warning() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      />
    </SvgIcon>
  )
}

export function Visibility() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
      />
    </SvgIcon>
  )
}

export function VisibilityOff() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
      />
    </SvgIcon>
  )
}
