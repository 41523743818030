import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Configuration from './configuration'
import * as Constants from './constants'
import * as FormComponents from './form-components'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as Notifications from '@rushplay/notifications'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Reselect from 'reselect'
import * as Session from './session'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {QueryPopup} from './query-popup'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: Constants.EmailPattern,
    },
    password: {
      type: 'string',
      pattern: Constants.PasswordPattern,
    },
    terms: {
      type: 'boolean',
      const: true,
    },
    promo: {
      type: 'boolean',
      const: true,
    },
  },
  required: ['email', 'password', 'terms', 'promo'],
}

const getRegistrationData = Reselect.createStructuredSelector({
  affiliateClickId: (state) => Analytics.getClickId(state.analytics),
  netrefererBTag: (state) => Analytics.getBtag(state.analytics),
  clientType: (state) => Configuration.getClientType(state.configuration),
  utmCampaign: (state) => Analytics.getUtmCampaign(state.analytics),
  utmMedium: (state) => Analytics.getUtmMedium(state.analytics),
  utmSource: (state) => Analytics.getUtmSource(state.analytics),
})

export function Register() {
  const translate = Herz.I18n.useTranslate()
  const dispatch = ReactRedux.useDispatch()
  const history = ReactRouterDom.useHistory()
  const registrationData = ReactRedux.useSelector(getRegistrationData)
  const closeRegisterQuery = Hooks.useSafeUpdateQuery({register: null})

  function onSubmit(data) {
    const userData = {
      ...registrationData,
      countryCode: 'JP',
      email: data.email,
      generateUsername: true,
      language: 'ja',
      password: data.password,
      passwordConfirmation: data.password,
    }

    dispatch([
      Processes.start('REGISTER_LOADING'),
      Api.registerPlayer(userData, {
        success: () => [
          Processes.stop('REGISTER_LOADING'),
          Processes.start('LOGIN_LOADING'),
          Session.login({...userData}, () =>
            history.push(`?${closeRegisterQuery}`)
          ),
        ],
        failure: (res) => {
          if (res.status >= 500) {
            return [
              Notifications.add({
                message: 'errors.general.unknown',
                level: 'error',
              }),
              Processes.stop('REGISTER_LOADING'),
            ]
          }
          if (R.not(R.isEmpty(res.value.errors))) {
            if (R.path(['errors', 'email'], res.value)) {
              return [
                Notifications.add({
                  message: 'errors.email-already-exists',
                  level: 'error',
                }),
                Processes.stop('REGISTER_LOADING'),
              ]
            }

            return [
              Notifications.add({
                message: `errors.${res.status}`,
                level: 'error',
              }),
              Processes.stop('REGISTER_LOADING'),
            ]
          }
        },
        version: 1,
      }),
    ])
  }

  const loading = ReactRedux.useSelector((state) =>
    Processes.isRunning(state.processes, {
      ids: ['LOGIN_LOADING', 'REGISTER_LOADING'],
    })
  )

  return (
    <Forms.Provider
      name="register"
      schema={dataSchema}
      onSubmit={(errors, userData) => {
        if (R.isEmpty(errors)) {
          onSubmit(userData)
        }
      }}
    >
      <QueryPopup activeQueryName="register">
        <Suspense.Boundary>
          <ThemeUi.Grid
            sx={{
              color: 'static-white',
              gridGap: 2,
            }}
          >
            <ThemeUi.Heading
              as="h1"
              sx={{
                fontSize: 4,
                textAlign: 'center',
              }}
            >
              {translate('register.title')}
            </ThemeUi.Heading>
            <ThemeUi.Heading
              as="h3"
              sx={{
                fontSize: [1, 2],
                textAlign: 'center',
                pb: 3,
                color: 'highlight-text',
              }}
            >
              <ThemeUi.Box as="span" sx={{fontSize: 4, mr: 1}}>
                \
              </ThemeUi.Box>
              {translate('register.sub-title')}
              <ThemeUi.Box as="span" sx={{fontSize: 4, ml: 0}}>
                /
              </ThemeUi.Box>
            </ThemeUi.Heading>
            <ThemeUi.Grid
              sx={{
                gridGap: 2,
                pb: 1,
              }}
            >
              <FormComponents.InputField
                autoComplete="email"
                inputMode="email"
                scope="#/properties/email"
              />
              <FormComponents.PasswordInputField
                autoComplete="password"
                scope="#/properties/password"
              />
              <ThemeUi.Box sx={{mt: 2}}>
                <FormComponents.CheckboxField scope="#/properties/terms" />
              </ThemeUi.Box>
              <ThemeUi.Box sx={{mt: 2}}>
                <FormComponents.CheckboxField scope="#/properties/promo" />
              </ThemeUi.Box>
            </ThemeUi.Grid>

            <FormComponents.SubmitButton
              loading={loading}
              sx={{
                my: 2,
              }}
            >
              {translate('register.submit')}
            </FormComponents.SubmitButton>

            <ThemeUi.Link
              as={ReactRouterDom.Link}
              to="?login=here"
              variant="footerNav"
              sx={{
                textAlign: 'center',
              }}
            >
              {translate('register.already-a-member')}
            </ThemeUi.Link>
          </ThemeUi.Grid>
        </Suspense.Boundary>
      </QueryPopup>
    </Forms.Provider>
  )
}

Herz.I18n.Loader.preload(
  [
    'register.already-a-member',
    'register.submit',
    'register.sub-title',
    'register.title',
  ],
  Register
)

// for @loadable/component
export default Register
