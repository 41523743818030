import * as Api from '@rushplay/api-client'
import * as Constants from './constants'
import * as FormComponents from './form-components'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as Notifications from '@rushplay/notifications'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {QueryPopup} from './query-popup'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: Constants.EmailPattern,
    },
  },
  required: ['email'],
}

export function PasswordRecovery() {
  const translate = Herz.I18n.useTranslate()
  const history = ReactRouterDom.useHistory()
  const dispatch = ReactRedux.useDispatch()

  return (
    <Forms.Provider
      name="passwordRecovery"
      schema={dataSchema}
      onSubmit={(errors, userData) => {
        if (R.isEmpty(errors)) {
          history.push('?login=me')
          dispatch([
            Api.resetPassword(userData.email, {version: 1}),
            Notifications.add({
              message: 'password-recovery.email-sent',
              level: 'success',
            }),
          ])
        }
      }}
    >
      <QueryPopup activeQueryName="password-recovery">
        <Suspense.Boundary>
          <ThemeUi.Grid
            sx={{
              color: 'static-white',
              gridGap: 2,
            }}
          >
            <ThemeUi.Grid
              sx={{
                gridGap: 1,
                gridTemplateColumns: 'max-content 1fr',
                pb: 2,
                alignItems: 'center',
              }}
            >
              <ThemeUi.Link
                as={ReactRouterDom.Link}
                to="?login=me"
                variant="footerNav"
                sx={{
                  'fontSize': '32px',
                  'm': '0px',
                  'display': 'flex',
                  'ml': '-12px',
                  'transition': 'opacity 100ms ease',
                  '&:hover': {
                    opacity: 0.6,
                  },
                }}
              >
                <Icons.NavigateBack />
              </ThemeUi.Link>
              <ThemeUi.Heading
                as="h1"
                sx={{
                  fontSize: 4,
                  textAlign: 'center',
                }}
              >
                {translate('password-recovery.title')}
              </ThemeUi.Heading>
            </ThemeUi.Grid>
            <ThemeUi.Box
              sx={{
                pb: 2,
                fontSize: [1, 2],
              }}
            >
              {translate('password-recovery.description')}
            </ThemeUi.Box>
            <FormComponents.InputField
              autoComplete="email"
              inputMode="email"
              scope="#/properties/email"
            />
            <FormComponents.SubmitButton
              sx={{
                my: 2,
              }}
            >
              {translate('password-recovery.submit')}
            </FormComponents.SubmitButton>
          </ThemeUi.Grid>
        </Suspense.Boundary>
      </QueryPopup>
    </Forms.Provider>
  )
}

Herz.I18n.Loader.preload(
  [
    'password-recovery.submit',
    'password-recovery.title',
    'password-recovery.description',
  ],
  PasswordRecovery
)

// for @loadable/component
export default PasswordRecovery
