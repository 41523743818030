import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from '../icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactSpring from 'react-spring'
import * as Suspense from '../suspense'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Component to display errors of specified field
 * @component _FieldResponse
 * @param {Object} props Components props
 * @param {string} props.scope Scope of field to show info about
 * @returns {ReactNode} Div with first available error of specified field
 */
function _FieldResponse(props) {
  const field = Forms.useField(props.scope, {noRegister: true})
  const firstError = R.pathOr({}, [0], field.errors)
  const errorKey = R.pathOr('', ['translationKey'], firstError)

  const translate = Herz.I18n.useTranslate(() => [errorKey], [errorKey])

  const visited = field.status !== Forms.FieldStatus.PRISTINE
  const valid = R.isEmpty(field.errors)
  const visuallyValid = !visited || valid
  const visible = !visuallyValid || (!valid && visited)

  const hide = !visible || valid || !field.value

  const transition = ReactSpring.useTransition(!hide, null, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      duration: 150,
    },
    leave: {
      display: 'none',
    },
  })

  return transition.map(
    ({item, key, props: styleProps}) =>
      item && (
        <ThemeUi.Message
          as={ReactSpring.animated.div}
          key={key}
          style={styleProps}
          variant="fieldError"
        >
          <ThemeUi.Grid
            sx={{
              placeItems: 'center',
              bg: 'danger',
              color: 'static-white',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              width: '25px',
              flexShrink: 0,
            }}
          >
            <Icons.Error />
          </ThemeUi.Grid>
          <ThemeUi.Grid
            sx={{
              placeItems: 'center',
              px: 1,
              py: 0,
            }}
          >
            {translate(errorKey, firstError.translationVariables)}
          </ThemeUi.Grid>
        </ThemeUi.Message>
      )
  )
}

_FieldResponse.propTypes = {
  scope: PropTypes.string.isRequired,
}

/**
 * Component that wraps _FieldResponse with a Suspense Boundary.
 * Used to catch suspense so not to trigger app-wide boundary.
 * @component FieldResponse
 * @returns {ReactNode}
 */
export function FieldResponse(props) {
  return (
    <Suspense.Boundary fallback={Suspense.Fallbacks.Empty}>
      <_FieldResponse {...props} />
    </Suspense.Boundary>
  )
}
